import 'font-awesome/css/font-awesome.min.css';


const Projects = () => (
    <main>
    <section>
        <h1>Projects I've Done</h1>
        <h2>Websites</h2>
        <ul>
            <li>
                <a className='blueLink' href='https://www.micahorton.com' 
                target='_blank' rel='noreferrer'>micahorton.com </a> 
                (<a className='italicLink' href='https://github.com/MicaHorton/micahorton.com' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>
                <a className='blueLink' href='https://www.hyfix.ai' 
                target='_blank' rel='noreferrer'>hyfix.ai </a> 
                (<a className='italicLink' href='https://github.com/MicaHorton/hyfix.ai' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>
                <a className='blueLink' href='https://www.fusionpedia.org' 
                target='_blank' rel='noreferrer'>fusionpedia.org </a> 
                (<a className='italicLink' href='https://github.com/MicaHorton/wendell-horton' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>
                <a className='blueLink' href='https://clubhub.info/myPage.html' 
                target='_blank' rel='noreferrer'>clubhub.info </a> 
                (<a className='italicLink' href='https://github.com/MicaHorton/clubhub.info' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>
                HQRecyle website
                (<a className='italicLink' href='https://github.com/MicaHorton/wendell-horton' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>
                EqOpTech volunteer app
                (<a className='italicLink' href='https://github.com/MicaHorton/wendell-horton' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>
        </ul>

        <h2>Python Scripts</h2>
        <ul>
            <li>video scraper 
                (<a className='italicLink' href='https://github.com/MicaHorton/wendell-horton' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>personal database 
                (<a className='italicLink' href='https://github.com/MicaHorton/wendell-horton' 
                target='_blank' rel='noreferrer'>see repository</a>)
            </li>

            <li>small scripts</li>
            <ul>
                <li>gas laws calculator 
                    (<a className='italicLink' href='https://github.com/MicaHorton/video-scraper' 
                    target='_blank' rel='noreferrer'>see gist</a>)
                </li>

                <li>hangman game 
                    (<a className='italicLink' href='https://gist.github.com/MicaHorton/d886f01e0cad3497ab15da9a06bf263c' 
                    target='_blank' rel='noreferrer'>see gist</a>)
                </li>

                <li>password manager 
                    (<a className='italicLink' href='https://github.com/MicaHorton/other' 
                    target='_blank' rel='noreferrer'>see repository</a>)
                </li>

                <li>what to eat decider 
                    (<a className='italicLink' href='https://github.com/MicaHorton/other' 
                    target='_blank' rel='noreferrer'>see repository</a>)
                </li>
            </ul>
        </ul>
                    

        <h2>Other</h2>
        <ul>
            <li>Little Library Project (C++) 
                (<a className='italicLink' href='https://github.com/MicaHorton/little-library' 
                target='_blank' rel='noreferrer'>see repository</a>) 
            </li>
            <li>server_tcp and server_udp scripts for HYFIX (Node.js)</li>
            <li>marshmallow toaster</li>
        </ul>
                

    </section>
    </main>

);

export default Projects;


/*

More information about each project can be found in the README.md file of the repository.
Websites I've Built
- this website 
- hyfix.ai
- Wendell Horton ()
- clubhub.info
- hqrecycle

Python Scripts
- webscraper scripts
- small scripts
    - Gas laws script
    - Hangman
    - Dinner Decider
    - personal database
    
Other
- Little Library Project (C++)
- server_tcp and server_udp scripts for HYFIX (Node.js)
- Marshmallow toaster


frontend
- hyfix website
- clubhub website
- grandy's website
- this website
- hqrecyle

backend
- python web scraper
- video scraper

engineering
- marshmallow toaster
- 


*/