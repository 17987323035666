import "font-awesome/css/font-awesome.min.css";

const About = () => (
  <main>
    <section>
      <h1>Hi, there!</h1>
      <p>
        Unfortunately, this website has become too expensive to host, so I've
        taken it down temporarily. I plan to put it back up in the future
        sometime when my budget allows :/ The original code and screenshots of
        how the website looked before can be seen on my Github at
        github.com/MicaHorton.
        <br />
        <br />
        Cheers,
        <br />
        Mica
      </p>
    </section>
  </main>
);

export default About;

/*
 <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
 Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
*/
